import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { GENSET_REDUCER } from '@bvt-features/genset/redux';
import { MAINMAP_REDUCER } from '@bvt-features/mainmap/redux';
import { MAPP_REDUCER } from '@bvt-features/mapp/redux';
import { interactiveReducer } from './interactive/reducer';
import { HISTORY_REDUCER } from '@bvt-features/history/redux';
import { MAIN_MODULE_REDUCER } from '@bvt-features/main-module/redux';
import { ACCOUNT_REDUCER } from '@bvt-features/account/redux';
import { MAPANA_REDUCER } from '@bvt-features/mapana/redux';
import { DATEX_REDUCER } from '@bvt-features/datex/store/datex.slice';
import { TOUR_REDUCER } from '@bvt-features/tour/store/tour.slice';
import { authReducer } from '@bvt-features/auth/store/auth.slice';
import { SUBMODULE_REDUCER } from '@bvt-features/sub-module/store/subModuleRoot';
import { PERTAMINA_REDUCER } from '@bvt-features/pertamina/store/pertamina.root';
import { DANONE_REDUCER } from '@bvt-features/danone/danone.root';

const featureReducer = combineReducers({
  AUTH: authReducer,
  GENSET: GENSET_REDUCER,
  MAPP: MAPP_REDUCER,
  HISTORY: HISTORY_REDUCER,
  MAIN_MODULE: MAIN_MODULE_REDUCER,
  ACCOUNT: ACCOUNT_REDUCER,
  MAPANA: MAPANA_REDUCER,
  MAINMAP: MAINMAP_REDUCER,
  DATEX: DATEX_REDUCER,
  TOUR: TOUR_REDUCER,
  SUBMODULE: SUBMODULE_REDUCER,
  PERTAMINA: PERTAMINA_REDUCER,
  DANONE: DANONE_REDUCER,
});

export const rootReducer = combineReducers({
  features: featureReducer,
  interactive: interactiveReducer,
  form: formReducer,
});
