import { DanoneService } from '@bvt-features/danone/service/danoneService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const danoneService = new DanoneService();

export const getResultTerritoryPlanning = createAsyncThunk(
  'LI/DANONE/TERRITORY-PLANNING/GET_RESULT_TERRITORY_PLANNING',
  async (param, { rejectWithValue }) => {
    try {
      const response = await danoneService.getResultTerritoryPlanning(param);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
