import Resource from '../../../api/resource';

export class DanoneService extends Resource {
  constructor() {
    super('danone', '/api', 'api-asman-telkom', null);
  }

  getResultAreaProfiling(param) {
    this.setAdditionUri('/area/result');
    return this.create(param);
  }

  getAhsMarkers(param) {
    this.setAdditionUri('/markers');
    return this.list(param);
  }

  getResultTerritoryPlanning(param) {
    this.setAdditionUri('/territory/result');
    return this.create(param);
  }
}
