
import { useDispatch, useSelector } from 'react-redux';
import { droppableMarkerActions } from '../../store/droppable-marker/droppable_marker.slice';
import { asyncActionCheckMarkerIsValid } from '../../store/droppable-marker/droppable_marker.asyncAction';

export const useDanoneDroppableMarker = () => {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../../../redux/rootStore.js').rootStore.getState>} state
     */
    (state) => state.features.DANONE.droppableMarker
  );

  const dispatch = useDispatch();

  const addNewHistoryToList = (param) => {
    dispatch(droppableMarkerActions.addNewHistoryToList(param));
  };

  const clearHistoryList = (param) => {
    dispatch(droppableMarkerActions.clearHistoryLisst(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const changeDragStatus = (param) => {
    dispatch(droppableMarkerActions.changeDragStatus(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {number} param.payload
   */
  const setHistoryMarkerActiveIndex = (param) => {
    dispatch(droppableMarkerActions.setHistoryMarkerActiveIndex(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {number} param.payload
   */
  const setMarkerActiveIndex = (param) => {
    dispatch(droppableMarkerActions.setMarkerActiveIndex(param));
  };

  // CEK VALID AREA
  /**
   * @description
   * @param {object} param
   * @param {number} param.longitude
   * @param {number} param.latitude
   * @param {number} param.id_source
   */
  const checkIfAreaIsValid = (param) => {
    dispatch(asyncActionCheckMarkerIsValid(param || {}));
  };

  const resetState = () => {
    dispatch(droppableMarkerActions.resetState());
  };

  /**
   * @param {{ x: number, y: number }} payload
   */
  const setPoint = (payload) => {
    dispatch(droppableMarkerActions.setPoint(payload));
  };

  /**
   * @param {[string, string]} coordinate
   */
  const setCoordinate = (coordinate) => {
    dispatch(droppableMarkerActions.setCoordinate(coordinate));
  };

  return {
    state,
    addNewHistoryToList,
    clearHistoryList,
    checkIfAreaIsValid,
    changeDragStatus,
    setHistoryMarkerActiveIndex,
    setMarkerActiveIndex,
    resetState,
    setPoint,
    setCoordinate,
  };
};
