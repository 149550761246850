import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isActive: false,
  isOpen: false,
  isLoading: false,
  items: [],
};

const legendSlice = createSlice({
  name: 'LI/DANONE/LEGEND',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setChecked: (state, action) => {
      state.items = state.items.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            checked: action.payload.checked,
          };
        }
        return item;
      });
    },
    setOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setActive: (state, action) => {
      state.isActive = action.payload;
    },
  },
});

export const { reducer: legendReducer, actions: legendActions } = legendSlice;
