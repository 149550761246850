import CryptoJS from 'crypto-js';

const secretKey = 'BVT_FOTERAS';

// Helper function to convert base64 to base64URL (URL-safe base64)
function base64UrlEncode(base64) {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

// Helper function to convert base64URL to base64
function base64UrlDecode(base64Url) {
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  // Pad with equal signs to match base64 length
  while (base64.length % 4) {
    base64 += '=';
  }
  return base64;
}

// Encryption function
export function encrypt(text) {
  const ciphertext = CryptoJS.AES.encrypt(text, secretKey).toString();
  return base64UrlEncode(ciphertext);
}

// Decryption function
export function decrypt(encryptedText) {
  const base64 = base64UrlDecode(encryptedText);
  const bytes = CryptoJS.AES.decrypt(base64, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}
