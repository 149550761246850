import React from 'react';
import './PopupContent.scss';

function PopupPOI(props) {
  return (
    <div className='DanonePopupTerritory__popup'>
      <div className='header'>
        <div className='wrapper-title'>
          <div className='title' title={props.properties?.name}>
            {props.properties?.name}
          </div>
          <div className='subtitle'>{`${props.properties?.latitude}, ${props.properties?.longitude}`}</div>
        </div>
      </div>
      <div className='detail'>
        <div>ID Outlet</div>
        <div>{props.properties?.id_outlet}</div>
      </div>
      <div className='detail'>
        <div>Cluster AHS</div>
        <div>{props.properties?.cluster_ahs}</div>
      </div>
      <div className='detail'>
        <div>Depo</div>
        <div style={{ userSelect: 'text' }}>{props.properties?.depo}</div>
      </div>
      <div className='detail'>
        <div>Telp</div>
        <div style={{ userSelect: 'text' }}>{props.properties?.telpon}</div>
      </div>
      <div className='detail' style={{ alignItems: 'flex-start' }}>
        <div>Address</div>
        <div
          className='address'
          style={{ userSelect: 'text' }}
          title={props.properties?.address}
        >
          {props.properties?.address}
        </div>
      </div>
      <div className='detail'>
        <div>Village</div>
        <div style={{ userSelect: 'text' }}>{props.properties?.village}</div>
      </div>
      <div className='detail'>
        <div>District</div>
        <div style={{ userSelect: 'text' }}>{props.properties?.district}</div>
      </div>
      <div className='detail'>
        <div>City</div>
        <div style={{ userSelect: 'text' }}>{props.properties?.city}</div>
      </div>
      {props.click && (
        <a className='button' href={props?.href} target='_blank' type='button'>
          Area Profiling
        </a>
      )}
    </div>
  );
}

function PopupGeojsonTerritory(props) {
  return (
    <div className='DanonePopupTerritory__popup'>
      <div className='header'>
        <div className='wrapper-title'>
          <div className='title' title={props.properties?.name}>
            {props.properties?.name}
          </div>
        </div>
      </div>
      <div className='detail'>
        <div>Household :</div>
        <div>{props.properties?.household}</div>
      </div>
      <div className='detail'>
        <div>Coverage :</div>
        <div>{props.properties?.coverage}</div>
      </div>
    </div>
  );
}

function PopupGeojsonArea(props) {
  return (
    <div className='DanonePopupTerritory__popup'>
      <div className='header'>
        <div className='wrapper-title'>
          <div className='title' title={props.properties?.name}>
            {props.properties?.name}
          </div>
        </div>
      </div>
      <div className='detail'>
        <div>{props.properties?.area?.key} :</div>
        <div>{props.properties?.area?.value}</div>
      </div>
      <div className='detail'>
        <div>Coverage :</div>
        <div>{props.properties?.coverage}</div>
      </div>
    </div>
  );
}

/**
 *
 * @param {object} props
 * @param {'poi' | 'geojson'} props.type
 * @param {object} props.properties
 * @param {boolean} props.click
 * @param {string} props.href
 * @returns
 */
export default function PopupContent(props) {
  const { type, properties, click, href } = props;
  switch (type) {
    case 'poi':
      return (
        <PopupPOI click={click} href={href} properties={properties} />
      );
    case 'territory':
      return <PopupGeojsonTerritory properties={properties} />;
    case 'area':
      return <PopupGeojsonArea properties={properties} />;
    default:
      return null;
  }
}
