import { useDispatch, useSelector } from 'react-redux';
import { territoryPlanningActions } from '../../store/territory-planning/territory-planning';
import { getResultTerritoryPlanning } from '../../store/territory-planning/territory-planning.asyncAction';

export const useTerritoryPlanning = () => {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../../../redux/rootStore.js').rootStore.getState>} state
     */
    (state) => state.features.DANONE.territoryPlanning
  );
  const dispatch = useDispatch();

  const resetState = () => dispatch(territoryPlanningActions.resetState());
  const setShowMarker = (value) =>
    dispatch(territoryPlanningActions.showMarker(value));
  const setShowPolygon = (value) =>
    dispatch(territoryPlanningActions.showPolygon(value));
  const setStep = (value) => dispatch(territoryPlanningActions.setStep(value));
  const setShowSidebar = (value) =>
    dispatch(territoryPlanningActions.setShowSidebar(value
    ));

  /**
   * @param {*} param
   * @param {string} param.kode_provinsi
   * @param {string} param.kode_kota
   * @param {string} param.kode_kecamatan
   * @param {string} param.kode_desa
   */
  const getTerritoryResult = (param) =>
    dispatch(getResultTerritoryPlanning(param));

  return {
    state,
    resetState,
    setShowMarker,
    setShowPolygon,
    setStep,
    getTerritoryResult,
    setShowSidebar,
  };
};
