import React, { useEffect, useState, useRef } from 'react';
import { GeoJSON, useMap, FeatureGroup } from 'react-leaflet';
import { useAreaProfiling } from '../../hooks/useAreaProfiling';

export const DanonePolygonContainer = () => {
  const map = useMap();
  const areaProfiling = useAreaProfiling();
  const [geojson, setGeojson] = useState();
  const featureGroupRef = useRef(); // Reference for FeatureGroup

  useEffect(() => {
    if (areaProfiling.state.data?.area) {
      setGeojson(areaProfiling.state.data.area);
      areaProfiling.setShowPolygon(true);
    }
  }, [areaProfiling.state.data]);

  useEffect(() => {
    if (geojson && map && featureGroupRef.current) {
      // Once the GeoJSON data is rendered, adjust the map bounds
      const bounds = featureGroupRef.current.getBounds();
      map.fitBounds(bounds);
    }
  }, [geojson, map]);

  return (
    <>
      {geojson && areaProfiling.state.isShowPolygon ? (
        <FeatureGroup ref={featureGroupRef}>
          <GeoJSON
            data={geojson}
            style={{
              fillColor: 'rgba(36, 204, 184, 0.1)',
              color: '#20A49D',
              fillOpacity: 0.8,
              opacity: 1,
              weight: 2,
            }}
          />
        </FeatureGroup>
      ) : null}
    </>
  );
};
