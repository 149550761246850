import { createSlice } from '@reduxjs/toolkit';
import { asyncActionCheckMarkerIsValid } from './droppable_marker.asyncAction';

/**
 * @description For marker history
 * @typedef TMarker
 * @type {Object}
 * @property {number} id
 * @property {number} lat
 * @property {number} long
 * @property {number} id_provinsi
 * @property {string} nama_provinsi
 * @property {number} id_kota
 * @property {string} nama_kota
 * @property {number} id_kecamatan
 * @property {string} nama_kecamatan
 * @property {number} id_desa
 * @property {string} nama_desa
 */

/**
 * @typedef initialStateModel
 * @type {object}
 * @property {number} activeMarkerIndex
 * @property {number} activeMarkerHistoryIndex
 * @property {Array<TMarker>} historyList
 * @property {boolean} draggable
 * @property {'IDLE'|'SUCCESS'|'FAILED' | 'LOADING'} status_PLACE
 * @property {{x: number, y: number}} point
 */

/**
 * @type {initialStateModel} initialState
 */
const initialState = {
  activeMarkerHistoryIndex: null,
  activeMarkerIndex: null,
  historyList: [],
  draggable: false,
  status_PLACE: 'IDLE',
  point: undefined,
  coordinate: [],
  activeMarkers: [],
};

export const danoneDroppableMarker = createSlice({
  name: 'LI/DANONE/AREA-PROFILING/droppable-marker',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {TMarker} action.payload.marker
     */
    addNewHistoryToList(state, action) {
      const {
        payload: { history },
      } = action;
      state.historyList.push(history);
      state.activeMarkerHistoryIndex = state.historyList.length - 1;
    },
    clearHistoryLisst(state) {
      state.historyList = [];
      state.activeMarkerHistoryIndex = null;
      state.activeMarkerIndex = null;
    },
    /**
     * @param {object} action
     * @param {boolean} action.payload
     */
    changeDragStatus(state, action) {
      const { payload } = action;
      state.draggable = payload;
    },

    /**
     * @param {object} action
     * @param {boolean} action.payload
     */
    setMarkerActiveIndex(state, action) {
      const { payload } = action;
      state.activeMarkerIndex = payload;
    },

    /**
     * @param {object} action
     * @param {boolean} action.payload
     */
    setHistoryMarkerActiveIndex(state, action) {
      const { payload } = action;
      state.activeMarkerHistoryIndex = payload;
    },
    setPoint(state, { payload }) {
      state.point = payload;
    },
    setCoordinate(state, { payload }) {
      state.coordinate = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(asyncActionCheckMarkerIsValid.pending, (state) => {
        state.status_PLACE = 'LOADING';
      })
      .addCase(asyncActionCheckMarkerIsValid.fulfilled, (state, action) => {
        const { payload } = action;
        state.status_PLACE = 'SUCCESS';
        state.historyList.push(payload.result);
        state.activeMarkerHistoryIndex = state.historyList.length - 1;
        state.point = undefined;
      })
      .addCase(asyncActionCheckMarkerIsValid.rejected, (state) => {
        state.status_PLACE = 'FAILED';
        state.point = undefined;
      })
});

export const {
  reducer: droppableMarkerReducer,
  actions: droppableMarkerActions,
} = danoneDroppableMarker;
