import React from 'react';
import PropTypes from 'prop-types';
import * as BvtIcon from 'bvt-icon';
import './PopupContent.scss';
import { Svg } from '@bvt-atoms';
import { getIcon } from '@bvt-shared/utils/iconUtils';

export default function PopupContent(props) {
  return (
    <div className='DanonePopupPOI__popup'>
      <div className='header'>
        <div className='icon'>
          {
            props.properties.category_icon
              ? <Svg src={getIcon('poi', props?.properties?.category_icon)} type='link' />
              : <BvtIcon.DefaultPoi height='22' width='22' />
          }
        </div>
        <div className='wrapper-title'>
          <div className='title' title={props.properties?.poi_name}>
            {props.properties?.poi_name}
          </div>
          <div className='subtitle'>
            {props.properties?.industry_name}
          </div>
        </div>
      </div>
      <div className='detail'>
        <div>Group</div>
        <div>{props.properties?.group_name}</div>
      </div>
      <div className='detail'>
        <div>Category</div>
        <div>{props.properties?.category_name}</div>
      </div>
      <div className='line' />
      <div className='detail'>
        <div>Longitude</div>
        <div style={{ userSelect: 'text' }}>{props.properties?.longitude}</div>
      </div>
      <div className='detail'>
        <div>Latitude</div>
        <div style={{ userSelect: 'text' }}>{props.properties?.latitude}</div>
      </div>
      <div className='detail'>
        <div>Address</div>
        <div
          className='address'
          style={{ userSelect: 'text' }}
          title={props.properties?.address}
        >
          {props.properties?.address}
        </div>
      </div>
    </div>
  );
}

PopupContent.propTypes = {
  properties: PropTypes.object,
};
