import React, { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import MarkerCluster from '../../components/MarkerCluster';
import PopupContent from '../../components/PopupContent';
import { useAreaProfiling } from '../../hooks/useAreaProfiling';
import { useDanoneLegend } from '@bvt-features/danone/legend/hooks/useDanoneLegend';
import { useLocation } from 'react-router';

export const DanonePoiCollectionContainer = () => {
  const initialMap = useMap();
  const areaProfiling = useAreaProfiling();
  const legend = useDanoneLegend();
  const location = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (areaProfiling.state.data?.poi) {
      const poi = areaProfiling.state.data?.poi;
      const poiChecked = legend.state.items.filter((v) => v.checked).map(v => v.icon) || [];
      const mappedData = poi
        .filter(v => {
          if (location.pathname.includes('dashboard') || location.pathname.includes('preview-pdf')) {
            return true;
          }
          return poiChecked.includes(v.properties.category_icon);
        })
        .map((v) => ({
          position: [v.properties.latitude, v.properties.longitude],
          properties: { ...v.properties },
          popupContent: <PopupContent properties={v.properties} />,
        }));
      setData(mappedData);
      areaProfiling.setShowPOI(true);
    }
  }, [areaProfiling.state.data, legend.state.items]);

  if (initialMap && areaProfiling.state.isShowPOI) {
    return <MarkerCluster data={data} />;
  }

  return null;
};
