import React, { useState } from 'react';
import DynamicIcon from '@bvt-icons';
import './DanoneLegendContainer.scss';
import { useMap } from 'react-leaflet';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { Input, Spin } from 'antd';
import _ from 'lodash';
import { getIcon } from '@bvt-shared/utils/iconUtils';
import { useDanoneLegend } from '../../hooks/useDanoneLegend';
import { useLocation } from 'react-router';
import { IconSearch } from '@bvt-assets/icon/icon-search';

const LegendItem = (props) => {
  const { data, handleChange, path, onChangeSearch } = props;
  const totalChecked = data.filter((item) => item.checked).length;
  return (
    <>
      {path.includes('area-profiling') && (
        <span className='danone__legend__content__title_parent'>
          {totalChecked}/5 POI Selected
        </span>
      )}
      {path.includes('area-profiling') && (
        <Input
          addonAfter={<IconSearch />}
          className='danone__legend__content__search'
          onChange={onChangeSearch}
          placeholder='Search'
        />
      )}
      {_.orderBy(data, ['checked'], ['desc']).map((item) => (
        <div className='danone__legend__content__li' key={item.id}>
          {path.includes('area-profiling') && (
            <div
              className={`custom-checkbox ${item.checked ? 'checked' : ''}`}
              onClick={() => {
                if (totalChecked < 5 || item.checked) {
                  handleChange(item.id, !item.checked);
                }
              }}
            />
          )}
          <img
            className='danone__legend__content__li--icon'
            src={
              item.icon.includes('/Category')
                ? getIcon('poi', item.icon)
                : item.icon
            }
          />
          <div className='danone__legend__content__li--desc'>{item.title}</div>
        </div>
      ))}
    </>
  );
};

export const DanoneLegendContainer = () => {
  const LEGEND = useDanoneLegend();
  const initialMap = useMap();
  const handleOpenLegend = () => {
    LEGEND.setToggleOpen(!LEGEND.state?.isOpen);
  };
  const uLocation = useLocation();
  const [search, setSearch] = useState('');

  const mapp = useMainMap();

  const emptyContent = (
    <li className='danone__legend__content__li center'>NO CONTENT</li>
  );

  if (!LEGEND.state?.isActive) {
    return null;
  }

  return (
    <div
      className={`danone__legend`}
      onDoubleClick={() => initialMap.doubleClickZoom.disable()}
      onMouseEnter={() => initialMap.scrollWheelZoom.disable()}
      onMouseLeave={() => initialMap.scrollWheelZoom.enable()}
      style={{
        right: mapp.state.activeMenu
          ? mapp.state.activeMenuWidth + 8 + 'px'
          : '0.5rem',
      }}
    >
      <div className='danone__legend__wrapper'>
        <div
          className={`danone__legend__content ${
            LEGEND.state?.isOpen ? 'show' : ''
          } scroll`}
        >
          {LEGEND.state?.isLoading ? (
            <Spin className='danone__legend__content__loader' />
          ) : (
            <ul className='danone__legend__content__ul'>
              {LEGEND.state.items.length === 0 ? (
                emptyContent
              ) : (
                <LegendItem
                  data={LEGEND.state.items.filter((item) =>
                    item.title.toLowerCase().includes(search.toLowerCase())
                  )}
                  handleChange={LEGEND.handleChecked}
                  onChangeSearch={(e) => setSearch(e.target.value)}
                  path={uLocation.pathname}
                />
              )}
            </ul>
          )}
        </div>
        <div className='danone__legend__action'>
          <button
            className={`danone__legend__button ${
              LEGEND.state?.isOpen ? 'show' : ''
            }`}
            onClick={handleOpenLegend}
          >
            <div className='danone__legend__button__container'>
              {LEGEND.state?.isOpen ? 'Hide Legend' : 'Show Legend'}
              <DynamicIcon
                className={`danone__legend__button--arrow ${
                  LEGEND.state?.isOpen ? 'show' : ''
                }`}
                height='0.588rem'
                icon='arrow'
                width='0.588rem'
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
