import { useDispatch, useSelector } from 'react-redux';
import { legendActions } from '../../store/legend.slice';

export const useDanoneLegend = () => {
  const state = useSelector((state) => state.features.DANONE.legend);
  const dispatch = useDispatch();

  const resetState = () => dispatch(legendActions.resetState());

  /**
   * @param {boolean} isLoading
   * @returns {void}
   */
  const setLoading = (isLoading) =>
    dispatch(legendActions.setLoading(isLoading));

  const showLegend = () => dispatch(legendActions.setActive(true));
  const hideLegend = () => dispatch(legendActions.setActive(false));
  /**
   * Represents a legend item.
   * @typedef {Object} Legend
   * @property {number} id - Unique identifier for the legend.
   * @property {string} title - Display name of the legend.
   * @property {string} icon - Path to the legend icon.
   * @property {boolean} checked - Indicates whether the legend is selected.
   */

  /**
   * An array of legend items.
   * @param {Legend[]} items
   * @returns {void} This function doesn't return anything.
   */
  const setItems = (items) => dispatch(legendActions.setItems(items));
  /**
   * @param {boolean} isOpen
   * @returns {void}
   * */
  const setToggleOpen = (isOpen) => dispatch(legendActions.setOpen(isOpen));
  /**
   * @param {number|string} id
   * @param {boolean} checked
   * @returns {void}
   */
  const handleChecked = (id, checked) =>
    dispatch(legendActions.setChecked({ id, checked }));

  return {
    resetState,
    setLoading,
    setItems,
    setToggleOpen,
    handleChecked,
    showLegend,
    hideLegend,
    state,
  };
};
