import { createAsyncThunk } from '@reduxjs/toolkit';
import { DanoneService } from '@bvt-features/danone/service/danoneService';

const danoneService = new DanoneService();

export const getResultAreaProfiling = createAsyncThunk(
  'LI/DANONE/AREA-PROFILING/GET_RESULT_AREA_PROFILING',
  async (param, { rejectWithValue }) => {
    try {
      const response = await danoneService.getResultAreaProfiling(param);
      if(response.data.error) {
        return rejectWithValue(response.data);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAhsMArkers = createAsyncThunk(
  'LI/DANONE/AREA-PROFILING/GET_MARKERS',
  async (param, { rejectWithValue }) => {
    try {
      const response = await danoneService.getAhsMarkers(param);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
