import { createSlice } from '@reduxjs/toolkit';
import { getResultTerritoryPlanning } from './territory-planning.asyncAction';

const initialState = {
  status_TERRITORY_RESULT: 'IDLE',
  description: '',
  message: '',
  data: {},
  isShowMarker: false,
  isShowPolygon: false,
  step: 1,
  showSidebar: true,
};
export const territorySlice = createSlice({
  name: 'LI/DANONE/TERRITORY-PLANNING',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    showMarker: (state, action) => {
      state.isShowMarker = action.payload;
    },
    showPolygon: (state, action) => {
      state.isShowPolygon = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getResultTerritoryPlanning.pending, (state) => {
        state.status_TERRITORY_RESULT = 'LOADING';
      })
      .addCase(getResultTerritoryPlanning.fulfilled, (state, action) => {
        state.status_TERRITORY_RESULT = 'SUCCESS';
        state.data = action.payload;
      })
      .addCase(getResultTerritoryPlanning.rejected, (state, action) => {
        state.status_TERRITORY_RESULT = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      }),
});

export const {
  reducer: territoryPlanningReducer,
  actions: territoryPlanningActions,
} = territorySlice;
