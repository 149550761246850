import React, { useEffect, useRef } from 'react';
import { GeoJSON, useMap } from 'react-leaflet';
import * as L from 'leaflet';
import MakerAHS from '@bvt-features/danone/assets/marker-ahs.png';
import { useTerritoryPlanning } from '../../hooks/useTerritoryPlanning';
import _ from 'lodash';
import PopupContent from '../../components/PopupContent';
import { renderToString } from 'react-dom/server';
import './DanoneTerritoryGeojson.scss';
import { encrypt } from '../../../../../utils/encrypt';
import { useDanoneLegend } from '@bvt-features/danone/legend/hooks/useDanoneLegend';
import catchmentInfo from '@bvt-features/danone/assets/catchment-info.png';
import areaInfo from '@bvt-features/danone/assets/area-info.png';
import markerAhsLegend from '@bvt-features/danone/assets/marker-ahs-legend.png';

const myIcon = L.icon({
  iconUrl: MakerAHS,
  iconSize: [36, 42],
  iconAnchor: [18, 40],
});

const getFeatureStyle = (feature) => {
  const defaultStyles = {
    MultiPolygon: {
      fillColor: 'rgba(54, 73, 97, 0.1)',
      color: 'rgba(54, 73, 97, 0.50)',
      opacity: 0.7,
      fillOpacity: 0.7,
      weight: 2,
    },
    Polygon: {
      fillColor: 'rgba(7, 130, 124, 0.2)',
      color: 'rgba(7, 130, 124, 1)',
      opacity: 0.7,
      fillOpacity: 0.7,
      weight: 2,
    },
    default: {
      color: '#000000',
      weight: 1,
    },
  };

  const type = feature.geometry?.type || 'default';
  return {
    ...defaultStyles[type],
    ...(feature.properties?.style || {}),
  };
};

export const DanoneTerritoryGeojson = () => {
  const territoryStore = useTerritoryPlanning();
  const initialMap = useMap();
  const data = territoryStore.state.data;
  const isClick = useRef(false);
  const legend = useDanoneLegend();

  useEffect(() => {
    if (!_.isEmpty(data.area) && initialMap) {
      const bounds = L.geoJSON(data.area).getBounds();
      if (bounds.isValid()) {
        initialMap.fitBounds(bounds, {
          paddingTopLeft: [20, 150],
          paddingBottomRight: [20, 20],
        });
      }
    }
  }, [data.area, initialMap]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      legend.showLegend();
      const territoryLegend = [];
      if (!_.isEmpty(data.poi)) {
        territoryLegend.push({
          id: 'poi',
          title: 'Aqua Home Service',
          icon: markerAhsLegend,
        });
      }
      if (!_.isEmpty(data.territory)) {
        territoryLegend.push({
          id: 'territory',
          title: 'Catchment Household',
          icon: catchmentInfo,
        });
      }
      if (!_.isEmpty(data.area)) {
        territoryLegend.push({
          id: 'area',
          title: 'Administrative Area',
          icon: areaInfo,
        });
      }
      legend.setItems(territoryLegend);
      legend.setToggleOpen(true);
    }
  }, [data]);

  const getFeatureType = (feature) => {
    if (feature === data.area) {
      return 'Area';
    }
    if (data.territory.includes(feature)) {
      return 'Territory';
    }
    if (data.poi.includes(feature)) {
      return 'POI';
    }
    return 'Unknown';
  };

  const getFeatureProperties = (feature, type) => {
    const props = feature.properties || {};
    if (type === 'POI') {
      return {
        name: props.Customer_Name || 'N/A',
        latitude: feature.geometry.coordinates?.[1] || 0,
        longitude: feature.geometry.coordinates?.[0] || 0,
        id_outlet: props.Customer_Id || 'N/A',
        cluster_ahs: props.bill_nickname || 'N/A',
        depo: props.depo_name || 'N/A',
        telpon: props.telpon || 'N/A',
        address: props.Address || 'N/A',
        village: props.Kelurahan || 'N/A',
        district: props.Kecamatan || 'N/A',
        city: props.Kota || 'N/A',
      };
    } else if (type === 'Territory') {
      return {
        name: 'Catchment Info',
        household: props.household || 'N/A',
        coverage:  props.luas_km ?new Intl.NumberFormat('id-ID').format(props.luas_km * 1000) + ' m2' : 'N/A',
      };
    } else if (type === 'Area') {
      const [key, value] =
        Object.entries(props).find(([key]) => key.includes('nama')) || [];
      let area;
      if (key.includes('provinsi')) {
        area = 'Province';
      } else if (key.includes('kota')) {
        area = 'City';
      } else if (key.includes('kecamatan')) {
        area = 'District';
      } else if (key.includes('desa')) {
        area = 'Village';
      }
      return {
        name: 'Area Info',
        area: { key: area, value },
        coverage:
          props.luas ? new Intl.NumberFormat('id-ID').format(props.luas) + ' m2' : 'N/A',
      };
    }
    return null;
  };

  const handleFeatureClick = (feature, layer) => {
    if (!feature.properties) {
      return;
    }

    const featureType = getFeatureType(feature);
    const properties = getFeatureProperties(feature, featureType);

    if (!properties) {
      return;
    }

    const popupContent = renderToString(
      <PopupContent properties={properties} type={featureType.toLowerCase()} />
    );

    if (featureType === 'POI') {
      layer.on('click', () => {
        layer.unbindPopup();
        layer.unbindTooltip();
        isClick.current = true;
        const form = encrypt(
          JSON.stringify({
            customer_id: feature.properties.Customer_Id,
          })
        );
        layer
          .bindPopup(
            renderToString(
              <PopupContent
                click={isClick.current}
                href={`/app/map/map-analytic/danone-area-profiling?form=${form}`}
                properties={properties}
                type={featureType.toLowerCase()}
              />
            ),
            { closeButton: true, offset: [0, -20], interactive: true }
          )
          .openPopup();
      });
    }

    layer.on('popupclose', () => {
      isClick.current = false;
    });

    layer.on('mouseover', () => {
      if (!isClick.current) {
        layer
          .bindTooltip(popupContent, {
            direction: 'top',
            sticky: true,
            className: 'DanoneTerritoryGeojson__tooltip-container',
          })
          .openTooltip();
      }
    });

    layer.on('mouseout', () => {
      layer.unbindTooltip();
    });
  };

  return (
    <>
      {initialMap && !_.isEmpty(data) && (
        <GeoJSON
          data={{
            type: 'FeatureCollection',
            features: [
              data.area,
              ...(data.territory || []),
              ...(data.poi || []),
            ],
          }}
          onEachFeature={handleFeatureClick}
          pointToLayer={(feature, latlng) => {
            return L.marker(latlng, { icon: myIcon });
          }}
          style={getFeatureStyle}
        />
      )}
    </>
  );
};
