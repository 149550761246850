import { combineReducers } from 'redux';
import { areaProfilingReducer } from './area-profiling/store/area-profiling/area-profiling.slice';
import { legendReducer } from './legend/store/legend.slice';
import { droppableMarkerReducer } from './area-profiling/store/droppable-marker/droppable_marker.slice';
import { territoryPlanningReducer } from './territory-planning/store/territory-planning/territory-planning';

export const DANONE_REDUCER = combineReducers({
  areaProfiling: areaProfilingReducer,
  territoryPlanning: territoryPlanningReducer,
  legend: legendReducer,
  droppableMarker: droppableMarkerReducer,
});
