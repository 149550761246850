import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import * as BvtIcon from 'bvt-icon';
import 'leaflet.markercluster';
import 'leaflet.markercluster.placementstrategies';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import _ from 'lodash';
import { Space } from 'antd';
import { Svg } from '@bvt-atoms';
import { getIcon } from '@bvt-shared/utils/iconUtils';

const parseStringHTML = (param) => {
  try {
    return JSON.parse(
      new DOMParser()
        .parseFromString(param, 'text/html')
        .querySelector('.DanoneMarkerCluster').dataset.meta
    );
  } catch (error) {
    return {};
  }
};

const StripRenderer = (props) => {
  return (
    <Space align='center'>
      {props.icon ? <Svg src={getIcon('poi', props.icon)} type='link' /> : <BvtIcon.DefaultPoi height='22' width='22' />}
      <div style={{ fontSize: '0.75rem', fontWeight: '600' }}>
        {props.name}
      </div>
      <div style={{ fontSize: '0.75rem' }}>{props.number}</div>
    </Space>
  );
};

/**
 * @param {object} props
 * @param {{
 *  position: [number, number]
 *  popupContent: React.JSX.Element
 *  properties: {
 *   category_name: string
 *   category_icon: string
 *   category_code: string
 *   group_name: string
 *   group_code: string
 *   industry_code: string
 *   industry_name: string
 *   industry_icon_color: string
 *   address: string
 *   longitude: number
 *   latitude: number
 *   poi_id: number
 *   poi_name: string
 *  }
 * }} props.data
 */
export default function MarkerCluster(props) {
  const map = useMap();

  useEffect(() => {
    let markerClusterGroupInstance = new L.MarkerClusterGroup({
      animate: false,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      maxClusterRadius: 32,
      elementsPlacementStrategy: 'concentric',
      elementsMultiplier: 1,
      spiderfyDistanceMultiplier: 1.2,
      firstCircleElements: 5,
      animateAddingMarkers: false,
      iconCreateFunction: (icfParam) => {
        const ndta = icfParam
          .getAllChildMarkers()
          .map((vgacm) => parseStringHTML(vgacm.getIcon().options.html));
        const groupedByCategory = _.groupBy(ndta, 'category_name');
        const categories = _.map(groupedByCategory, (ctgr, key) => ({
          category_name: key,
          count: ctgr.length,
          icon: ctgr?.[0]?.category_icon
        }));
        const multiplier = map.getZoom() > 16 ? 8 : 3;
        const totalMarkerPerCluster = icfParam.getChildCount();
        const percentageOfTotalMarker =
          (totalMarkerPerCluster / props.data.length) * 100;
        const opacityMultiplier = (percentageOfTotalMarker / 10) * multiplier;
        const sizeMultiPlier = opacityMultiplier > 0.9 ? 1.3 : 0.8;

        const baseStyle = {
          width: 52 * sizeMultiPlier,
          height: 52 * sizeMultiPlier,
          borderRadius: 9999,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: `rgba(248,129,129,0.1)`,
          opacity: 1,
        };
        const htmlElm = renderToStaticMarkup(
          <div style={baseStyle}>
            <div
              style={{
                ...baseStyle,
                width: 42 * sizeMultiPlier,
                height: 42 * sizeMultiPlier,
                background: `rgba(248,129,129,0.2)`,
              }}
            >
              <div
                style={{
                  ...baseStyle,
                  width: 32 * sizeMultiPlier,
                  height: 32 * sizeMultiPlier,
                  background: `rgba(248,129,129,${opacityMultiplier})`,
                }}
              >
                <div
                  style={{ color: '#fff', fontSize: '1rem', fontWeight: 600 }}
                >
                  {totalMarkerPerCluster}
                </div>
              </div>
            </div>
          </div>
        );

        const htmlToolTipElm = renderToStaticMarkup(
          <Space direction='vertical'>
            <div style={{ fontSize: '0.75rem', fontWeight: '600' }}>
              Classification (Count)
            </div>
            {categories.map((v, index) => {
              const idx = index + 1;
              return (
                <StripRenderer icon={v.icon} key={idx} name={v.category_name} number={v.count} />
              );
            })}
          </Space>
        );
        icfParam.bindTooltip(
          L.tooltip({ content: htmlToolTipElm, direction: 'top', sticky: true })
        );

        return L.divIcon({ html: htmlElm, iconAnchor: { x: 22, y: 22 } });
      },
    });

    markerClusterGroupInstance.setZIndex(101);

    if (props?.data?.length > 0) {
      // Add Cluster To Map
      props.data?.forEach((el) => {

        // Define New instance of marker
        let markerLayer = L.marker(el.position, {
          icon: new L.DivIcon({
            html: renderToStaticMarkup(
              <div
                className='DanoneMarkerCluster'
                data-meta={JSON.stringify(el.properties)}
              >
                {el.properties.category_icon ? <img
                  alt=''
                  src={getIcon('poi', el.properties.category_icon)}
                  style={{ height: '2.25rem', width: '2.25rem' }}
                /> : <BvtIcon.DefaultPoi height='22' width='22' />}
              </div>
            ),
            iconSize: [25, 41],
            iconAnchor: [18, 35],
            tooltipAnchor: [0, -32],
            shadowSize: [41, 41],
          }),
        });

        // Bind Tooltip To marker
        markerLayer.bindPopup(
          L.popup({
            content: React.isValidElement(el.popupContent)
              ? renderToStaticMarkup(el.popupContent)
              : el.popupContent,
            direction: 'top',
            className: 'DanonePopupPOI MarkerCluster__child-tooltip',
            offset: {
              x: 0,
              y: -16,
            },
          })
        );

        // Add Marker To cluster Layer
        markerClusterGroupInstance.addLayer(markerLayer);
      });

      markerClusterGroupInstance.addTo(map);
    }
    return () => {
      // Remove Cluster On Remount
      markerClusterGroupInstance.removeFrom(map);
    };
  }, [props.data]);

  return null;
}
