import { useDispatch, useSelector } from 'react-redux';
import { areaProfilingActions } from '../../store/area-profiling/area-profiling.slice';
import { getAhsMArkers, getResultAreaProfiling,  } from '../../store/area-profiling/area-profilisng.asyncAction';

export const useAreaProfiling = () => {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../../../redux/rootStore.js').rootStore.getState>} state
     */
    (state) => state.features.DANONE.areaProfiling
  );
  const dispatch = useDispatch();

  const resetState = () => dispatch(areaProfilingActions.resetState());
  const setShowPOI = (value) => dispatch(areaProfilingActions.showPOI(value));
  const setShowPolygon = (value) =>
    dispatch(areaProfilingActions.showPolygon(value));
  const setStep = (value) => dispatch(areaProfilingActions.setStep(value));
  const resetStatus = () => dispatch(areaProfilingActions.resetStatus());

  /**
   *
   * @param {object} param
   * @param {string} param.provinsi
   * @param {string} param.kodepodes
   * @param {string} param.customer_id
   * @param {number} param.latitude
   * @param {number} param.longitude
   * @returns
   */
  const getAreaProfilingResult = (param) =>
    dispatch(getResultAreaProfiling(param));

  const getMarkers = (param) => dispatch(getAhsMArkers(param));
  return {
    state,
    resetState,
    setShowPOI,
    setShowPolygon,
    setStep,
    resetStatus,
    getAreaProfilingResult,
    getMarkers,
  };
};
