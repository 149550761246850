import { createSlice } from '@reduxjs/toolkit';
import { getAhsMArkers, getResultAreaProfiling } from './area-profilisng.asyncAction';

const initialState = {
  description: '',
  message: '',
  data: {},
  marker: {
    list: [],
    meta: {
      total: 0,
      page: 1,
      limit: 30,
      nextPage: 2,
      prevPage: 1,
    }
  },
  isShowPOI: false,
  isShowPolygon: false,
  step: 1,
  status_GET_RESULT: 'IDLE',
  status_GET_MARKERS: 'IDLE',
};
export const areaSlice = createSlice({
  name: 'LI/DANONE/AREA-PROFILING',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    resetStatus: (state) => {
      state.status_GET_RESULT = 'IDLE';
    },
    showPOI: (state, action) => {
      state.isShowPOI = action.payload;
    },
    showPolygon: (state, action) => {
      state.isShowPolygon = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getResultAreaProfiling.pending, (state) => {
        state.status_GET_RESULT = 'LOADING';
      })
      .addCase(getResultAreaProfiling.fulfilled, (state, action) => {
        state.status_GET_RESULT = 'SUCCESS';
        state.data = action.payload.data;
      })
      .addCase(getResultAreaProfiling.rejected, (state, action) => {
        state.status_GET_RESULT = 'FAILED';
        state.message = action.error.message;
      })

      .addCase(getAhsMArkers.pending, (state) => {
        state.status_GET_MARKERS = 'LOADING';
      })
      .addCase(getAhsMArkers.fulfilled, (state, action) => {
        state.status_GET_MARKERS = 'SUCCESS';
        state.marker.list = action.payload.data;
        state.marker.meta = {
          total: action.payload.total,
          page: action.payload.page,
          limit: action.payload.limit,
          nextPage: action.payload.next_page,
          prevPage: action.payload.prev_page,
        };
      })
      .addCase(getAhsMArkers.rejected, (state, action) => {
        state.status_GET_MARKERS = 'FAILED';
        state.message = action.error.message;
      })
});

export const { reducer: areaProfilingReducer, actions: areaProfilingActions } =
  areaSlice;
