import React from 'react';
import {
  MapAnalysis,
  SiteProfiling,
  GridAnalysis,
  MapBrowsing,
  BusinessMonitoring,
  Thematic,
  Poi,
  BusinessMonitoringDisabled,
  GeneralSetup,
  CreateEditParameter,
  MapBrowsingDisabled,
  ThematicDisabled,
  PoiDisabled,
  BusinessProfiling,
  // eslint-disable-next-line import/no-unresolved
} from 'bvt-icon';
import DynamicIcon from '@bvt-icons';

/**
 * @typedef TLink
 * @property {string} text
 * @property {string} url
 * @property {string} icon
 * @property {string} color
 * @property {boolean} disabled
 * @property {Function} disabledIcon
 * @property {Array<TLink>} child
 * @property {string} permission
 */

/**
 * @type {Array<TLink>} linklistconfig
 */
export const mainHomeLinkBoxModule = [
  {
    text: '',
    url: '',
    icon: '',
    path: 'map',
    color: undefined,
    disabled: false,
    child: [
      {
        background: '__orange',
        text: 'Map Browsing',
        icon: () => <MapBrowsing />,
        disabledIcon: () => <MapBrowsingDisabled />,
        slug: 'map-browsing',
      },
      {
        text: 'Business Monitoring',
        icon: () => <BusinessMonitoring />,
        disabledIcon: () => <BusinessMonitoringDisabled />,
        slug: 'business-monitoring',
      },
      {
        text: 'Thematic',
        icon: () => <Thematic />,
        disabledIcon: () => <ThematicDisabled />,
        slug: 'thematic',
      },
      {
        text: 'Point of intereset',
        icon: () => <Poi />,
        disabledIcon: () => <PoiDisabled />,
        slug: 'point-of-interest',
      },
    ],
  },
];

export const mainHomeLinkBoxAnalyze = [
  {
    text: '',
    url: '',
    icon: '',
    path: 'map',
    color: undefined,
    disabled: false,
    child: [
      {
        background: '__blue',
        text: 'Map Analysis',
        icon: () => <MapAnalysis />,
        slug: 'map-analytic',
        path: '/app/map/map-analytic/',
        permission: 'MAPANA',
      },
      {
        text: 'Site Profiling',
        icon: () => <SiteProfiling />,
        slug: 'site-profiling',
        path: '/app/map/map-analytic/site-profiling',
        permission: 'MAPANA.SIPRO',
      },
      {
        text: 'Grid Analysis',
        icon: () => <GridAnalysis />,
        slug: 'grid-analysis',
        path: '/app/map/map-analytic/grid-analysis',
        permission: 'MAPANA.GRIANA',
      },
      {
        text: 'Site Profiling Mandala',
        icon: () => <SiteProfiling />,
        slug: 'site-profiling-mandala',
        path: '/app/map/map-analytic/site-profiling-mandala',
        permission: 'RND.SIPRO-MANDALA',
      },
      {
        text: 'Grid Analysis Lawu',
        icon: () => <GridAnalysis />,
        slug: 'grid-analysis-lawu',
        path: '/app/map/map-analytic/grid-analysis-lawu',
        permission: 'RND.GRIANA-LAWU',
      },
      {
        text: 'Asset Management Telkom Property',
        icon: () => (
          <DynamicIcon
            color='#477ffd'
            height='3.75rem'
            icon={'assetManagement'}
            width='3.75rem'
          />
        ),
        slug: 'asset-management',
        path: '/sub-module',
        permission: 'MAPANA.ASMANTELKOM',
      },
      {
        text: 'NFR Business Analysis',
        icon: () => (
          <DynamicIcon
            color='#477ffd'
            height='3.75rem'
            icon={'pertaminaIcon'}
            width='3.75rem'
          />
        ),
        slug: 'asset-management-pertamina',
        path: '/app/map/map-analytic/asset-management-pertamina',
        permission: 'MAPANA.ASMANPERTAMINA',
      },
      {
        text: 'Area Profling',
        icon: () => <SiteProfiling />,
        slug: 'danone-area-profiling',
        path: '/app/map/map-analytic/danone-area-profiling',
        permission: 'MAPANA.DANONE.AREA_PROFILING',
      },
      {
        text: 'Territory Planning',
        icon: () => <BusinessProfiling />,
        slug: 'danone-territory-planning',
        path: '/app/map/map-analytic/danone-territory-planning',
        permission: 'MAPANA.DANONE.TERRITORY_PLANNING',
      },
    ],
  },
];

export const mainHomeLinkBoxSetup = [
  {
    text: '',
    url: '',
    icon: '',
    path: 'menu',
    color: undefined,
    disabled: false,
    child: [
      {
        background: '__tosca',
        text: 'Parameter Setup',
        icon: () => <GeneralSetup />,
        slug: 'parameter-setup',
        path: '/app/menu/parameter-setup',
        permission: 'GENSET',
      },
      {
        text: 'POI Parameter',
        icon: () => (
          <svg height='60' viewBox='0 0 60 60' width='60'>
            <defs>
              <radialGradient
                cx='0.5'
                cy='0.5'
                gradientTransform='matrix(0.997, 0.075, -0.079, 1.049, 0.041, -0.062)'
                gradientUnits='objectBoundingBox'
                id='radial-gradient'
                r='0.463'
              >
                <stop offset='0' stopColor='#6ad4cf' />
                <stop offset='1' stopColor='#defffd' />
              </radialGradient>
              <linearGradient
                gradientUnits='objectBoundingBox'
                id='linear-gradient'
                x1='0.755'
                x2='0.755'
                y1='0.202'
                y2='1.05'
              >
                <stop offset='0' stopColor='#19b3ab' />
                <stop offset='0.639' stopColor='#0c6964' />
                <stop offset='1' stopColor='#07827c' />
              </linearGradient>
              <radialGradient
                cx='0.207'
                cy='0.747'
                gradientTransform='matrix(0.884, -0.636, 0.728, 0.772, -0.507, 0.3)'
                id='radial-gradient-3'
                r='0.556'
                xlinkHref='#radial-gradient'
              />
              <linearGradient
                gradientUnits='objectBoundingBox'
                id='linear-gradient-2'
                x1='0.756'
                x2='0.756'
                y1='0.202'
                y2='1.05'
              >
                <stop offset='0' stopColor='#19b3ab' />
                <stop offset='0.671' stopColor='#0b635f' />
                <stop offset='1' stopColor='#0c948d' />
              </linearGradient>
            </defs>
            <g
              data-name='Group 6814'
              id='Group_6814'
              transform='translate(24558 14605)'
            >
              <rect
                data-name='Rectangle 4116'
                fill='#fff'
                height='60'
                id='Rectangle_4116'
                opacity='0'
                transform='translate(-24558 -14605)'
                width='60'
              />
              <g
                data-name='Group 6774'
                id='Group_6774'
                transform='translate(-101 -213.519)'
              >
                <g
                  data-name='Group 6733'
                  id='Group_6733'
                  transform='translate(-24447 -14347.131)'
                >
                  <ellipse
                    cx='20'
                    cy='7.306'
                    data-name='Ellipse 737'
                    fill='url(#radial-gradient)'
                    id='Ellipse_737'
                    rx='20'
                    ry='7.306'
                  />
                  <ellipse
                    cx='12.277'
                    cy='3.898'
                    data-name='Ellipse 738'
                    fill='url(#radial-gradient)'
                    id='Ellipse_738'
                    rx='12.277'
                    ry='3.898'
                    transform='translate(7.723 1.777)'
                  />
                </g>
                <path
                  d='M1316.947,50.1a19.99,19.99,0,0,1,20,19.985c0,11.773-12.293,20.441-20,27.984-7.7-7.543-20-16.21-20-27.984a19.984,19.984,0,0,1,20-19.985Zm0,10.811a9.18,9.18,0,1,1-9.185,9.18A9.177,9.177,0,0,1,1316.947,60.911Z'
                  data-name='Path 2007'
                  fill='url(#linear-gradient)'
                  fillRule='evenodd'
                  id='Path_2007'
                  transform='translate(-25743.949 -14439.581)'
                />
              </g>
              <path
                d='M1331.148,34.7h17.438a4.8,4.8,0,0,1,4.784,4.8V56.978a4.8,4.8,0,0,1-4.784,4.8h-8.716l-9.927,3.472c-2.349.818-3.573.738-3.573-1.977V39.5a4.8,4.8,0,0,1,4.784-4.8Z'
                data-name='Path 2008'
                fill='url(#radial-gradient-3)'
                fillRule='evenodd'
                id='Path_2008'
                transform='translate(-25853.473 -14637.7)'
              />
              <path
                d='M107.877,19.751,97.485,30.143a.561.561,0,0,1-.4.179l-2.62.179a.615.615,0,0,1-.65-.649l.179-2.62a.561.561,0,0,1,.179-.4l10.392-10.392a.609.609,0,0,1,.873,0l2.441,2.441a.608.608,0,0,1,0,.873Zm-.381-6.271.918-.918a.609.609,0,0,1,.873,0L111.729,15a.608.608,0,0,1,0,.873l-.918.918a.609.609,0,0,1-.873,0L107.5,14.354A.609.609,0,0,1,107.5,13.481Z'
                data-name='Path 2315'
                fill='url(#linear-gradient-2)'
                fillRule='evenodd'
                id='Path_2315'
                transform='translate(-24616.465 -14610.378)'
              />
            </g>
          </svg>
        ),
        slug: 'poi',
        path: '/app/menu/parameter-setup/poi',
        permission: 'GENSET.POI',
      },
      {
        text: 'Thematic Parameter',
        icon: () => <CreateEditParameter />,
        slug: 'thematic',
        path: '/app/menu/parameter-setup/thematic',
        permission: 'GENSET.PARAMETER',
      },
    ],
  },
];
